export const DefaultFlagsValues = {
    STANDARDS_ACCESS: false,
    INVENTION_TRACKER_ENABLED: false,
    AI_CONCEPT_GENERATION_ENABLED: true,
    USERS_DISCOVERABILITY: 'all',
    SCOPING_TOOL_ENABLED: true,
    OPPORTUNITY_ATLAS_ENABLED: false,
    OPPORTUNITY_ATLAS_TIME_SLIDER_ENABLED: false,
    CONTEXT_ENABLED: false,
    USER_PROMPT_DEBUG_ENABLED: false,
    SCOPING_PAGE_ACCESS_ENABLED: false
};
